import axios from "axios";
import * as ILLOVOCAREERSAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new ILLOVOCAREERSAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API,
});

const Account: ILLOVOCAREERSAPI.AccountApi = new ILLOVOCAREERSAPI.AccountApi(globalConf);
const ApplicantProfiles: ILLOVOCAREERSAPI.ApplicantProfilesApi = new ILLOVOCAREERSAPI.ApplicantProfilesApi(globalConf);
const Vacancies: ILLOVOCAREERSAPI.VacanciesApi = new ILLOVOCAREERSAPI.VacanciesApi(globalConf);
const VacancyApplications: ILLOVOCAREERSAPI.VacancyApplicationsApi = new ILLOVOCAREERSAPI.VacancyApplicationsApi(globalConf);
const VacancyCountries: ILLOVOCAREERSAPI.VacancyCountriesApi = new ILLOVOCAREERSAPI.VacancyCountriesApi(globalConf);
const VacancyLocations: ILLOVOCAREERSAPI.VacancyLocationsApi = new ILLOVOCAREERSAPI.VacancyLocationsApi(globalConf);
const Countries: ILLOVOCAREERSAPI.CountriesApi = new ILLOVOCAREERSAPI.CountriesApi(globalConf);
const Ethnicities: ILLOVOCAREERSAPI.EthnicitiesApi = new ILLOVOCAREERSAPI.EthnicitiesApi(globalConf);
const FieldsOfStudy: ILLOVOCAREERSAPI.FieldsOfStudyApi = new ILLOVOCAREERSAPI.FieldsOfStudyApi(globalConf);
const Genders: ILLOVOCAREERSAPI.GendersApi = new ILLOVOCAREERSAPI.GendersApi(globalConf);
const Qualifications: ILLOVOCAREERSAPI.QualificationsApi = new ILLOVOCAREERSAPI.QualificationsApi(globalConf);
const YearsOfExperience: ILLOVOCAREERSAPI.YearsOfExperienceApi = new ILLOVOCAREERSAPI.YearsOfExperienceApi(globalConf);
const AreasOfDisability: ILLOVOCAREERSAPI.AreasOfDisabilityApi = new ILLOVOCAREERSAPI.AreasOfDisabilityApi(globalConf);
const Files: ILLOVOCAREERSAPI.FilesApi = new ILLOVOCAREERSAPI.FilesApi(globalConf);

export { Account, ApplicantProfiles, Vacancies, VacancyApplications, VacancyCountries, VacancyLocations, Countries, Ethnicities, FieldsOfStudy, Genders, Qualifications, YearsOfExperience, AreasOfDisability, Files };
