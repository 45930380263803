import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import { createHead } from "@unhead/vue";
import ElementPlus from "element-plus";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createGtm } from '@gtm-support/vue-gtm';
import "../scss/app.scss";

import { ConfigureAxios } from "@/network/auth-interceptor";

// Set 1st day of week to Monday for Element UI date pickers
dayjs.Ls.en.weekStart = 1;
dayjs.extend(utc);
dayjs.extend(timezone);

ConfigureAxios();
const app = createApp(App);
const head = createHead();
const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(
  createGtm({
    id: ["GTM-PLRPCT"], // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: process.env.VUE_APP_ENABLE_GTM === 'true' ? true : false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
  }),
);

app.use(ElementPlus);
app.use(pinia);
app.use(head);
app.use(router);

app.mount("#app");
