import { defineStore } from 'pinia';
import { checkStore } from "@/utilities/index";

type UserState = {
  userRole: string | null;
  userExpiry: number | null;
  userId: string | null;
};

const getUserInfoFromToken = (token: string) => {
  return (new Promise<any>((resolve, reject) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const parsedJSON = JSON.parse(jsonPayload)
    resolve(parsedJSON);
  }))
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => {
    return {
      userRole: null,
      userExpiry: null,
      userId: null,
    }
  },
  actions: {
    // Mutations
    setRole(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["role"] = value;
      this.userRole = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setExpiry(value: number | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["expiry"] = value;
      this.userExpiry = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setId(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["id"] = value;
      this.userId = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },

    // Actions
    getUserInfo: function (token: string) {
      return new Promise<void>(async (resolve, reject) => {
        const data = await getUserInfoFromToken(token);

        if (!data) {
          throw Error('Verification failed, please Login again.')
        }

        let role = typeof data['role'] === 'object' ? data['role']?.[0] : data['role'];
        let expiry = data['exp'];
        let id = typeof data['sub'] === 'object' ? data['sub']?.[0] : data['sub'];
        
        this.setRole(role);
        this.setExpiry(expiry * 1000);
        this.setId(id);

        resolve()
      });
    },
    clearUser: async function () {
      this.setRole(null);
      this.setExpiry(null);
      this.setId(null);
      localStorage.clear();
    },
  },
  getters: {
    role(state) {
      return checkStore(state, "userRole", "user", "role");
    },
    expiry(state) {
      return checkStore(state, "userExpiry", "user", "expiry");
    },
    id(state) {
      return checkStore(state, "userId", "user", "id");
    },
  }
})
