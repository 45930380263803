import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { UserRoles } from "@/types";
import { ComputedRef, computed } from "vue";

export const userPermissons: ComputedRef<UserRoles> = computed(() => {
  const userStore = useUserStore();
  const authStore = useAuthStore();

  let permissions: UserRoles = {
    admin: false,
  }

  if(authStore.isLoggedIn) {
    permissions = {
      admin: userStore.role?.includes("Admin") || false,
    }
  }

  return permissions;
})

export const isAdmin: ComputedRef<boolean> = computed(() => {
  return userPermissons.value.admin || false;
})