import { defineStore } from "pinia";

type MiscState = {
  miscIsLoading: boolean;
  miscIsLoadingOverride: boolean;
};

export const useMiscStore = defineStore({
  id: "misc",
  state: (): MiscState => {
    return {
      miscIsLoading: false,
      miscIsLoadingOverride: false,
    };
  },
  actions: {
    // Mutations
    setIsLoading(value: boolean) {
      this.miscIsLoading = value;
    },
    setIsLoadingOverride(value: boolean) {
      this.miscIsLoadingOverride = value;
    },
  },
  getters: {
    isLoading(state) {
      return state.miscIsLoading as boolean;
    },
    isLoadingOverride(state) {
      return state.miscIsLoadingOverride as boolean;
    },
  },
});
