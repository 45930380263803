import { ProblemDetails } from "@/api-client";
import { ElMessage } from "element-plus";
import { useMiscStore } from '@/stores/misc';
import { ValidateFieldsError } from "async-validator";

export function handleError(error: ProblemDetails | any, displayMessage = true) {
  console.log(error);

  const miscStore = useMiscStore();
  const defaultError = "<p>An unexpected error has occurred.</p>";
  let errorMessage = defaultError;

  setTimeout(() => {
    miscStore.setIsLoading(false);
    miscStore.setIsLoadingOverride(false);
  }, 500);

  if(displayMessage) {
    if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
      errorMessage =
      `<p>${(error.response?.data as ProblemDetails).title || (error.response?.data as ProblemDetails).detail  || defaultError}</p>`;

      if(error?.response?.data?.errors) {
        const errorMessages = error.response.data.errors;
        if(Object.keys(errorMessages).length) {
          let arr: string[] = []
          Object.keys(errorMessages).forEach((key:any) => {
            arr.push(`<p class='m-0'>${errorMessages[key]}</p>`);
          })
          errorMessage += arr.join("")
        }
      }
    } else if (error) {
      errorMessage = error.message || defaultError;
    }

    ElMessage.error({ message: errorMessage, showClose: true, duration: 0, grouping: true, dangerouslyUseHTMLString: true });
  }
}

export function handleInvalidForm(fields: ValidateFieldsError) {
  if (fields) {
    const field = Object.values(fields)[0];
    if (field[0]?.field) {
      document.getElementsByName(field[0].field)[0]?.focus();
    }
  }
  console.log("invalid");
}
