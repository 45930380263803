import { ElMessage } from "element-plus";
import { useAuthStore } from "@/stores/auth";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocation,
} from "vue-router";
import AdminLayout from "@/layouts/AdminLayout.vue";
import HomeView from "@/views/HomeView.vue";
import ApplicantProfileView from "@/views/ApplicantProfileView.vue";
import GraduatesBursariesView from "@/views/GraduatesBursariesView.vue";
import RegisterView from "@/views/RegisterView.vue";
import VacanciesSearchView from "@/views/VacanciesSearchView.vue";
import VacancyDetailView from "@/views/VacancyDetailView.vue";
import CareersView from "@/views/CareersView.vue";
import LoginView from "@/views/LoginView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import CookiePolicyView from "@/views/CookiePolicyView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsAndConditionsView from "@/views/TermsAndConditionsView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import EmailVerificationView from "@/views/EmailVerificationView.vue";
import AdminDashboardView from "@/views/admin/AdminDashboardView.vue";
import AdminVacanciesListView from "@/views/admin/vacancies/ListView.vue";
import AdminVacanciesEditView from "@/views/admin/vacancies/EditView.vue";
import AdminVacanciesCreateView from "@/views/admin/vacancies/CreateView.vue";
import AdminVacancyApplicationsListView from "@/views/admin/vacancy-applications/ListView.vue";
import AdminVacancyApplicationsDetailView from "@/views/admin/vacancy-applications/DetailView.vue";
import { userPermissons } from "@/utilities/auth";

function authGuard(to: RouteLocation) {
  const authStore = useAuthStore();
  const needsAdminPermission = to.fullPath.includes('admin') && !userPermissons.value.admin;
  const whiteList = ['Login', 'Register', 'ForgotPassword', 'EmailVerification'];

  if(whiteList.includes(to.name as string) && authStore.authIsLoggedIn) {
    ElMessage.info({message: 'You are already logged in.', showClose: true})
    router.push({ name: "ApplicantProfile" });
    return true;
  } else if(!to.fullPath.includes('admin') && !['ApplicantProfile'].includes(to.name as string)) {
    return true;
  } else if(!authStore.authIsLoggedIn) {
    // not logged in so redirect to login page with the return url
    if(to.name !== "Login") {
      ElMessage.info({message: 'Please log in to access this area.', showClose: true})
      router.push({ name: "Login", query: { returnUrl: to.fullPath || undefined  } });
    }
    return false;
  } else if(needsAdminPermission){
    ElMessage.info({message: 'You do not have permission to access this area.', showClose: true})
    router.push({ name: "Home" });
  } else {
    return true;
  }
}

const routes: Array<RouteRecordRaw> = [
  // PUBLIC START 
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    // beforeEnter: nonAuthAguard,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    // beforeEnter: nonAuthAguard,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/email-verification",
    name: "EmailVerification",
    component: EmailVerificationView,
    props: true
  },
  {
    path: "/about-us",
    name: "About",
    redirect: "https://www.illovosugarafrica.com/about-us",
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    redirect: "https://www.illovosugarafrica.com/contact-us/group-office",
  },
  {
    path: "/my-profile",
    name: "ApplicantProfile",
    component: ApplicantProfileView
  },
  {
    path: "/search-vacancies",
    name: "VacanciesSearch",
    component: VacanciesSearchView,
  },
  {
    path: "/vacancy/:id",
    name: "VacancytDetail",
    component: VacancyDetailView,
    props: true
  },
  {
    path: "/graduates-and-bursaries",
    name: "GraduatesBursaries",
    component: GraduatesBursariesView,
  },
  {
    path: "/careers",
    name: "Careers",
    component: CareersView,
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicyView,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditionsView,
  },
  // PUBLIC END 


  // ADMIN PORTAL START
  {
    path: "/admin",
    name: "AdminDashboard",
    component: AdminDashboardView,
    meta: { layout: AdminLayout },
  },

      // ADMIN VACANCY MANAGEMENT
  {
    path: "/admin/vacancies",
    name: "AdminVacanciesList",
    component: AdminVacanciesListView,
    meta: { layout: AdminLayout },
  },
  {
    path: "/admin/vacancies/create",
    name: "AdminVacanciesCreate",
    component: AdminVacanciesCreateView,
    meta: { layout: AdminLayout },
    props: true
  },
  {
    path: "/admin/vacancies/:id",
    name: "AdminVacanciesEdit",
    component: AdminVacanciesEditView,
    meta: { layout: AdminLayout },
    props: true
  },

  // ADMIN APPLICATION MANAGEMENT
  {
    path: "/admin/vacancy-applications",
    name: "AdminVacancyApplicationsList",
    component: AdminVacancyApplicationsListView,
    meta: { layout: AdminLayout },
  },
  {
    path: "/admin/vacancy-applications/:id",
    name: "AdminVacanciesApplicationsDetail",
    component: AdminVacancyApplicationsDetailView,
    meta: { layout: AdminLayout },
    props: true
  },
  // ADMIN PORTAL END


  // MISC START
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  // MISC END
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const navBar = document.querySelector("#navbarNav") as HTMLElement;
      return { el: to.hash, top: (navBar?.offsetHeight + 5) || 0, behavior: "smooth" };
    } else if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from) => {
  authGuard(to)

  // Save the 'from' route name to meta
  // Used in conjuntion with the goBack method in utilitis/index.ts
  // Search this reference for all related code & examples: queryCoolness
  if (from.name) {
    to.meta.fromRoute = true;
  }

  ElMessage.closeAll("error");
  document.getElementById("navbar")?.classList?.remove("show");
});

export default router;
