
import { handleError } from "@/utilities/api";
import { VacancyViewModelPagedResult, VacancyViewModel, CreateVacancyCommand } from "@/api-client";
import { ElMessage } from "element-plus";
import { Vacancies, VacancyCountries, VacancyLocations } from "@/network/api";

export function vacanciesGet(
  pgNum: number = 1,
  pgSize: number = 10,
  isActive: boolean = false,
  country: string | undefined = undefined,
  location: string | undefined = undefined,
  searchTerm: string | undefined = undefined,
  ): Promise<VacancyViewModelPagedResult> {
  return new Promise((resolve, reject) => {
    Vacancies.apiVacanciesGet(pgNum, pgSize, isActive, country, location, searchTerm)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacanciesGetId(id: string): Promise<VacancyViewModel> {
  return new Promise((resolve, reject) => {
    Vacancies.apiVacanciesIdGet(id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacanciesPost(item: CreateVacancyCommand): Promise<string> {
  return new Promise((resolve, reject) => {
    Vacancies.apiVacanciesPost(item)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.success({ message: "Created!", showClose: true, grouping: true });
          resolve(res.data.value);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacanciesPut(item: VacancyViewModel): Promise<void> {
  return new Promise((resolve, reject) => {
    Vacancies.apiVacanciesIdPut(item.id as string, item)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.success({ message: "Updated!", showClose: true, grouping: true });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacanciesDelete(id: string): Promise<void> {
  return new Promise((resolve, reject) => {
    Vacancies.apiVacanciesIdDelete(id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.error({ message: "Item deleted.", showClose: true, grouping: true });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

// Dropdown Lists

export function vacancyCountriesDdlGet(includeInactiveVacancies = false): Promise<string[]> {
  return new Promise((resolve, reject) => {
    VacancyCountries.apiVacancyCountriesGet(includeInactiveVacancies)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyLocationsDdlGet(country: string): Promise<string[]> {
  return new Promise((resolve, reject) => {
    VacancyLocations.apiVacancyLocationsCountryGet(country)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}
