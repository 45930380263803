import { defineStore } from "pinia";
import { Account } from "@/network/api";
import { checkStore } from "@/utilities/index";
import { ApiAccountConfirmEmailPostRequest, ApiAccountLoginPostRequest, ApiAccountRegisterPostRequest, TokenResultDto } from "@/api-client";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { ElMessage } from "element-plus";

type AuthState = {
  authToken: TokenResultDto | null;
  authIsLoggedIn: boolean;
};

let _refreshingToken: Promise<TokenResultDto> | null = null;

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => {
  return {
      authToken: null, // use getter straight out of localstorage
      authIsLoggedIn: checkStore(this, "authToken", "auth", "token") != null,
    };
  },
  actions: {
    // Mutations
    setToken(value: TokenResultDto | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["auth"]) {
        store["auth"] = {};
      }
      store["auth"]["token"] = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
      this.authToken = value || null;
      this.authIsLoggedIn = value ? true : false;
    },

    // Actions
    login: function (params: ApiAccountLoginPostRequest) {
      return new Promise<void>((resolve, reject) => {
        Account.apiAccountLoginPost({
          email: params.email,
          password: params.password,
        })
          .then(async (res) => {
            const userStore = useUserStore();
            let token = res.data;
            this.setToken(token);

            if(token.authenticationToken) {
              await userStore.getUserInfo(token.authenticationToken)
            }
            resolve();
          })
          .catch((error) => {
            console.error("LOGIN FAILED", error);
            if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
              if(error.response?.status === 403) {
                ElMessage.error({ message: "Incorrect email or password.", showClose: true, grouping: true });
              }
            }
            reject(error);
          });
      });
    },
    logout: function () {
      return new Promise<void>((resolve, reject) => {
        const userStore = useUserStore();
        this.setToken(null);
        userStore.clearUser().then(() => {
          router.push({name: "Login"});
          resolve()
        });
      });
    },
    refresh: function () {
      return (_refreshingToken ??= new Promise<TokenResultDto>((resolve, reject) => {
        const authenticationToken = this.token?.["authenticationToken"];
        const refreshToken = this.token?.["refreshToken"];
        if(authenticationToken && refreshToken) {
          Account.apiAccountRefreshTokenPost(authenticationToken, refreshToken)
          .then(async (res) => {
            if(res.data) {
              const userStore = useUserStore();
              let token = res.data;
              this.setToken(token);

              if(token.authenticationToken) {
                await userStore.getUserInfo(token.authenticationToken)
              }

              resolve(token);
            }
          })
          .catch(async (error) => {
            await this.logout().then(() => {
              ElMessage.error({ message: "Please login.", showClose: true, grouping: true });
              router.push({ name: 'Login', query: { returnUrl: router.currentRoute.value.fullPath || undefined  } });
            })
            console.error("Refresh FAILED", error);
            reject(error);
          });
        } else {
          reject()
        }
      }).finally(() => (_refreshingToken = null)));
    },
    register: (params: ApiAccountRegisterPostRequest) => {
      return new Promise<void>((resolve, reject) => {
        Account.apiAccountRegisterPost({
          email: params.email,
          password: params.password
        })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error("REGISTER FAILED", error);
            reject(error);
          });
      });
    },
    resetPassword: (emailAddress: string, passwordResetToken: string, newPassword: string) => {
      return new Promise<void>((resolve, reject) => {
        Account.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("RESET PASSWORD FAILED", error);
            reject(error);
          });
      });
    },
    forgotPassword: (emailAddress: string) => {
      return new Promise<void>((resolve, reject) => {
        Account.apiAccountForgotPasswordPost(emailAddress)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("FORGOT PASSWORD FAILED", error);
            reject(error);
          });
      });
    },
    emailVerification: (params: ApiAccountConfirmEmailPostRequest) => {
      return new Promise<void>((resolve, reject) => {
        Account.apiAccountConfirmEmailPost(params)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("EMAIL VERIFICATION FAILED", error);
            reject(error);
          });
      });
    },
  },
  getters: {
    token(state) {
      return checkStore(state, "authToken", "auth", "token");
    },
    isLoggedIn(state) {
      return state.authIsLoggedIn;
    }
  },
});
