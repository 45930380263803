
import { handleError } from "@/utilities/api";
import { ApplicantProfileViewModel, ApplicantProfileViewModelPagedResult, CountryViewModel, EthnicityViewModel, FieldOfStudyViewModel, GenderViewModel, QualificationViewModel, YearsOfExperienceViewModel, CreateApplicantProfileCommand, ApiApplicantProfilesIdGetRequest, AreaOfDisabilityViewModel, AreasOfDisabilityApi } from "@/api-client";
import { ApplicantProfiles, Countries, Ethnicities, FieldsOfStudy, Genders, Qualifications, YearsOfExperience, AreasOfDisability } from "@/network/api";
import { ElMessage } from "element-plus";
import { scrollTop } from "@/utilities";


export function applicantProfilesGet(
  pgNum: number = 1,
  pgSize: number = 10,
  searchTerm: string | undefined = undefined
  ): Promise<ApplicantProfileViewModelPagedResult> {
  return new Promise((resolve, reject) => {
    ApplicantProfiles.apiApplicantProfilesGet(pgNum, pgSize, searchTerm)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function applicantProfilesGetId(id: string): Promise<ApplicantProfileViewModel> {
  return new Promise((resolve, reject) => {
    ApplicantProfiles.apiApplicantProfilesIdGet(id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error, false);
        reject(error);
      });
  });
}

export function applicantProfilesPost(item: CreateApplicantProfileCommand): Promise<string> {
  item.applicantProfileCourses = item.applicantProfileCourses.filter(course => course.courseDetails !== "")

  return new Promise((resolve, reject) => {
    ApplicantProfiles.apiApplicantProfilesPost(item)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          scrollTop()
          ElMessage.success({ message: "Created!", showClose: true, grouping: true });
          resolve(res.data.value);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function applicantProfilesPut(item: ApiApplicantProfilesIdGetRequest): Promise<void> {
  item.applicantProfileCourses = item.applicantProfileCourses.filter(course => course.courseDetails !== "")

  return new Promise((resolve, reject) => {
    ApplicantProfiles.apiApplicantProfilesIdPut(item.id as string, item)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          scrollTop()
          ElMessage.success({ message: "Updated!", showClose: true, grouping: true });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}


// Dropdown Lists

export function countriesDdlGet(): Promise<CountryViewModel[]> {
  return new Promise((resolve, reject) => {
    Countries.apiCountriesGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function ethnicitiesDdlGet(): Promise<EthnicityViewModel[]> {
  return new Promise((resolve, reject) => {
    Ethnicities.apiEthnicitiesGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function fieldsOfStudyDdlGet(): Promise<FieldOfStudyViewModel[]> {
  return new Promise((resolve, reject) => {
    FieldsOfStudy.apiFieldsOfStudyFieldOfStudiesGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function gendersDdlGet(): Promise<GenderViewModel[]> {
  return new Promise((resolve, reject) => {
    Genders.apiGendersGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function qualificationsDdlGet(): Promise<QualificationViewModel[]> {
  return new Promise((resolve, reject) => {
    Qualifications.apiQualificationsGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function yearsOfExperienceDdlGet(): Promise<YearsOfExperienceViewModel[]> {
  return new Promise((resolve, reject) => {
    YearsOfExperience.apiYearsOfExperienceGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function areasOfDisabilityDdlGet(): Promise<AreaOfDisabilityViewModel[]> {
  return new Promise((resolve, reject) => {
    AreasOfDisability.apiAreasOfDisabilityAreaOfDisabilitiesGet(1, 9999)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}