/* tslint:disable */
/* eslint-disable */
/**
 * Illovo.Careers API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiAccountConfirmEmailPostRequest
 */
export interface ApiAccountConfirmEmailPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAccountConfirmEmailPostRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccountConfirmEmailPostRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface ApiAccountLoginPostRequest
 */
export interface ApiAccountLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAccountLoginPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccountLoginPostRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ApiAccountRegisterPostRequest
 */
export interface ApiAccountRegisterPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAccountRegisterPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccountRegisterPostRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ApiApplicantProfilesGetRequest
 */
export interface ApiApplicantProfilesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'middleNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'passportNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'alternatePhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'genderId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'ethnicityId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'linkedInProfileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'cvRelativeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'acceptedTermsAndConditions': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'countryOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'disabilityDetails'?: string | null;
    /**
     * 
     * @type {Array<ApplicantProfileQualificationCreateUpdateModel>}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'applicantProfileQualifications': Array<ApplicantProfileQualificationCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileWorkExperienceCreateUpdateModel>}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'applicantProfileWorkExperience': Array<ApplicantProfileWorkExperienceCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileCourseCreateUpdateModel>}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'applicantProfileCourses': Array<ApplicantProfileCourseCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>}
     * @memberof ApiApplicantProfilesGetRequest
     */
    'applicantProfileAreasOfDisability': Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface ApiApplicantProfilesIdGetRequest
 */
export interface ApiApplicantProfilesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'middleNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'passportNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'alternatePhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'genderId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'ethnicityId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'linkedInProfileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'cvRelativeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'acceptedTermsAndConditions': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'countryOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'disabilityDetails'?: string | null;
    /**
     * 
     * @type {Array<ApplicantProfileQualificationCreateUpdateModel>}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'applicantProfileQualifications': Array<ApplicantProfileQualificationCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileWorkExperienceCreateUpdateModel>}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'applicantProfileWorkExperience': Array<ApplicantProfileWorkExperienceCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileCourseCreateUpdateModel>}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'applicantProfileCourses': Array<ApplicantProfileCourseCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>}
     * @memberof ApiApplicantProfilesIdGetRequest
     */
    'applicantProfileAreasOfDisability': Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface ApiUsersGetRequest
 */
export interface ApiUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiUsersGetRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersGetRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiUsersGetRequest
     */
    'roleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ApiUsersIdDeleteRequest
 */
export interface ApiUsersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiUsersIdDeleteRequest
     */
    'roleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ApiVacanciesGetRequest
 */
export interface ApiVacanciesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'applicationsOpenDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'commencementDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'responsibilities': string;
    /**
     * 
     * @type {number}
     * @memberof ApiVacanciesGetRequest
     */
    'positionsAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'openedDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'minimumRequirementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'minimumRequirementYearsOfExperience': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesGetRequest
     */
    'minimumRequirementSkillsAndKnowledge': string;
    /**
     * 
     * @type {Array<VacancyQuestionCreateUpdateModel>}
     * @memberof ApiVacanciesGetRequest
     */
    'vacancyQuestions': Array<VacancyQuestionCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface ApiVacanciesIdDeleteRequest
 */
export interface ApiVacanciesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'applicationsOpenDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'commencementDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'responsibilities': string;
    /**
     * 
     * @type {number}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'positionsAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'closedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'openedDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'minimumRequirementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'minimumRequirementYearsOfExperience': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'minimumRequirementSkillsAndKnowledge': string;
    /**
     * 
     * @type {Array<VacancyQuestionCreateUpdateModel>}
     * @memberof ApiVacanciesIdDeleteRequest
     */
    'vacancyQuestions': Array<VacancyQuestionCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface ApiVacancyApplicationsBulkUpdateStatusPutRequest
 */
export interface ApiVacancyApplicationsBulkUpdateStatusPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'vacancyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'searchTerm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'ethnicityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'genderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'qualificationId'?: string | null;
    /**
     * 
     * @type {VacancyApplicationStatus}
     * @memberof ApiVacancyApplicationsBulkUpdateStatusPutRequest
     */
    'vacancyApplicationStatusId': VacancyApplicationStatus;
}
/**
 * 
 * @export
 * @interface ApiVacancyApplicationsGetRequest
 */
export interface ApiVacancyApplicationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsGetRequest
     */
    'vacancyId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsGetRequest
     */
    'applicationChannel': string;
    /**
     * 
     * @type {Array<VacancyApplicationQuestionAnswerCreateModel>}
     * @memberof ApiVacancyApplicationsGetRequest
     */
    'vacancyApplicationQuestionAnswers': Array<VacancyApplicationQuestionAnswerCreateModel>;
}
/**
 * 
 * @export
 * @interface ApiVacancyApplicationsIdDeleteRequest
 */
export interface ApiVacancyApplicationsIdDeleteRequest {
    /**
     * 
     * @type {VacancyApplicationStatus}
     * @memberof ApiVacancyApplicationsIdDeleteRequest
     */
    'vacancyApplicationStatusId': VacancyApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiVacancyApplicationsIdDeleteRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ApplicantProfileAreaOfDisabilityCreateUpdateModel
 */
export interface ApplicantProfileAreaOfDisabilityCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileAreaOfDisabilityCreateUpdateModel
     */
    'areaOfDisabilityId': string;
}
/**
 * 
 * @export
 * @interface ApplicantProfileAreaOfDisabilityViewModel
 */
export interface ApplicantProfileAreaOfDisabilityViewModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileAreaOfDisabilityViewModel
     */
    'areaOfDisabilityId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileAreaOfDisabilityViewModel
     */
    'areaOfDisabilityName': string;
}
/**
 * 
 * @export
 * @interface ApplicantProfileCourseCreateUpdateModel
 */
export interface ApplicantProfileCourseCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileCourseCreateUpdateModel
     */
    'courseDetails': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileCourseCreateUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicantProfileQualificationCreateUpdateModel
 */
export interface ApplicantProfileQualificationCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'qualificationOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'qualificationId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'fieldOfStudyOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'fieldOfStudyId': string;
    /**
     * 
     * @type {number}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationCreateUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicantProfileQualificationViewModel
 */
export interface ApplicantProfileQualificationViewModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'qualificationOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'qualificationId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'qualificationName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'fieldOfStudyOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'fieldOfStudyId': string;
    /**
     * 
     * @type {number}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileQualificationViewModel
     */
    'fieldOfStudyName': string;
}
/**
 * 
 * @export
 * @interface ApplicantProfileViewModel
 */
export interface ApplicantProfileViewModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'middleNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'passportNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'alternatePhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'genderId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'ethnicityId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'linkedInProfileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'cvRelativeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantProfileViewModel
     */
    'acceptedTermsAndConditions': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'genderName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'countryOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'disabilityDetails'?: string | null;
    /**
     * 
     * @type {Array<ApplicantProfileQualificationViewModel>}
     * @memberof ApplicantProfileViewModel
     */
    'applicantProfileQualifications': Array<ApplicantProfileQualificationViewModel>;
    /**
     * 
     * @type {Array<ApplicantProfileWorkExperienceViewModel>}
     * @memberof ApplicantProfileViewModel
     */
    'applicantProfileWorkExperience': Array<ApplicantProfileWorkExperienceViewModel>;
    /**
     * 
     * @type {Array<ApplicantProfileCourseCreateUpdateModel>}
     * @memberof ApplicantProfileViewModel
     */
    'applicantProfileCourses': Array<ApplicantProfileCourseCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileAreaOfDisabilityViewModel>}
     * @memberof ApplicantProfileViewModel
     */
    'applicantProfileAreasOfDisability': Array<ApplicantProfileAreaOfDisabilityViewModel>;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'ethnicityName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileViewModel
     */
    'countryName': string;
}
/**
 * 
 * @export
 * @interface ApplicantProfileViewModelPagedResult
 */
export interface ApplicantProfileViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof ApplicantProfileViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantProfileViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantProfileViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantProfileViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<ApplicantProfileViewModel>}
     * @memberof ApplicantProfileViewModelPagedResult
     */
    'data': Array<ApplicantProfileViewModel>;
}
/**
 * 
 * @export
 * @interface ApplicantProfileWorkExperienceCreateUpdateModel
 */
export interface ApplicantProfileWorkExperienceCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceCreateUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceCreateUpdateModel
     */
    'yearsOfExperienceId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceCreateUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicantProfileWorkExperienceViewModel
 */
export interface ApplicantProfileWorkExperienceViewModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceViewModel
     */
    'yearsOfExperienceId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantProfileWorkExperienceViewModel
     */
    'yearsOfExperienceName': string;
}
/**
 * 
 * @export
 * @interface AreaOfDisabilityViewModel
 */
export interface AreaOfDisabilityViewModel {
    /**
     * 
     * @type {string}
     * @memberof AreaOfDisabilityViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AreaOfDisabilityViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AreaOfDisabilityViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface AreaOfDisabilityViewModelPagedResult
 */
export interface AreaOfDisabilityViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof AreaOfDisabilityViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof AreaOfDisabilityViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof AreaOfDisabilityViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof AreaOfDisabilityViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<AreaOfDisabilityViewModel>}
     * @memberof AreaOfDisabilityViewModelPagedResult
     */
    'data': Array<AreaOfDisabilityViewModel>;
}
/**
 * 
 * @export
 * @interface BulkUpdateStatusVacancyApplicationsCommand
 */
export interface BulkUpdateStatusVacancyApplicationsCommand {
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'vacancyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'searchTerm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'ethnicityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'genderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'qualificationId'?: string | null;
    /**
     * 
     * @type {VacancyApplicationStatus}
     * @memberof BulkUpdateStatusVacancyApplicationsCommand
     */
    'vacancyApplicationStatusId': VacancyApplicationStatus;
}
/**
 * 
 * @export
 * @interface ConfirmEmailDto
 */
export interface ConfirmEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CountryViewModel
 */
export interface CountryViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CountryViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CountryViewModelPagedResult
 */
export interface CountryViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof CountryViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof CountryViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<CountryViewModel>}
     * @memberof CountryViewModelPagedResult
     */
    'data': Array<CountryViewModel>;
}
/**
 * 
 * @export
 * @interface CreateApplicantProfileCommand
 */
export interface CreateApplicantProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'middleNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'passportNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'alternatePhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'genderId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'ethnicityId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'linkedInProfileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'cvRelativeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateApplicantProfileCommand
     */
    'acceptedTermsAndConditions': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'countryOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicantProfileCommand
     */
    'disabilityDetails'?: string | null;
    /**
     * 
     * @type {Array<ApplicantProfileQualificationCreateUpdateModel>}
     * @memberof CreateApplicantProfileCommand
     */
    'applicantProfileQualifications': Array<ApplicantProfileQualificationCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileWorkExperienceCreateUpdateModel>}
     * @memberof CreateApplicantProfileCommand
     */
    'applicantProfileWorkExperience': Array<ApplicantProfileWorkExperienceCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileCourseCreateUpdateModel>}
     * @memberof CreateApplicantProfileCommand
     */
    'applicantProfileCourses': Array<ApplicantProfileCourseCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>}
     * @memberof CreateApplicantProfileCommand
     */
    'applicantProfileAreasOfDisability': Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface CreateUserCommand
 */
export interface CreateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserCommand
     */
    'roleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateVacancyApplicationCommand
 */
export interface CreateVacancyApplicationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyApplicationCommand
     */
    'vacancyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyApplicationCommand
     */
    'applicationChannel': string;
    /**
     * 
     * @type {Array<VacancyApplicationQuestionAnswerCreateModel>}
     * @memberof CreateVacancyApplicationCommand
     */
    'vacancyApplicationQuestionAnswers': Array<VacancyApplicationQuestionAnswerCreateModel>;
}
/**
 * 
 * @export
 * @interface CreateVacancyCommand
 */
export interface CreateVacancyCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'applicationsOpenDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'commencementDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'responsibilities': string;
    /**
     * 
     * @type {number}
     * @memberof CreateVacancyCommand
     */
    'positionsAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'openedDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'minimumRequirementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'minimumRequirementYearsOfExperience': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVacancyCommand
     */
    'minimumRequirementSkillsAndKnowledge': string;
    /**
     * 
     * @type {Array<VacancyQuestionCreateUpdateModel>}
     * @memberof CreateVacancyCommand
     */
    'vacancyQuestions': Array<VacancyQuestionCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface EthnicityViewModel
 */
export interface EthnicityViewModel {
    /**
     * 
     * @type {string}
     * @memberof EthnicityViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EthnicityViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof EthnicityViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface EthnicityViewModelPagedResult
 */
export interface EthnicityViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof EthnicityViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EthnicityViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof EthnicityViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof EthnicityViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<EthnicityViewModel>}
     * @memberof EthnicityViewModelPagedResult
     */
    'data': Array<EthnicityViewModel>;
}
/**
 * 
 * @export
 * @interface FieldOfStudyViewModel
 */
export interface FieldOfStudyViewModel {
    /**
     * 
     * @type {string}
     * @memberof FieldOfStudyViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FieldOfStudyViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FieldOfStudyViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface FieldOfStudyViewModelPagedResult
 */
export interface FieldOfStudyViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof FieldOfStudyViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FieldOfStudyViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof FieldOfStudyViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof FieldOfStudyViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<FieldOfStudyViewModel>}
     * @memberof FieldOfStudyViewModelPagedResult
     */
    'data': Array<FieldOfStudyViewModel>;
}
/**
 * 
 * @export
 * @interface FilePath
 */
export interface FilePath {
    /**
     * 
     * @type {string}
     * @memberof FilePath
     */
    'relativePath'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FilePath
     */
    'absolutePath': boolean;
}
/**
 * 
 * @export
 * @interface GenderViewModel
 */
export interface GenderViewModel {
    /**
     * 
     * @type {string}
     * @memberof GenderViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GenderViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GenderViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface GenderViewModelPagedResult
 */
export interface GenderViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof GenderViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof GenderViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof GenderViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof GenderViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<GenderViewModel>}
     * @memberof GenderViewModelPagedResult
     */
    'data': Array<GenderViewModel>;
}
/**
 * 
 * @export
 * @interface GuidJsonResponse
 */
export interface GuidJsonResponse {
    /**
     * 
     * @type {string}
     * @memberof GuidJsonResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface QualificationViewModel
 */
export interface QualificationViewModel {
    /**
     * 
     * @type {string}
     * @memberof QualificationViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QualificationViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof QualificationViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface QualificationViewModelPagedResult
 */
export interface QualificationViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof QualificationViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof QualificationViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof QualificationViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof QualificationViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<QualificationViewModel>}
     * @memberof QualificationViewModelPagedResult
     */
    'data': Array<QualificationViewModel>;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RoleListViewModel
 */
export interface RoleListViewModel {
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StringJsonResponse
 */
export interface StringJsonResponse {
    /**
     * 
     * @type {string}
     * @memberof StringJsonResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface TokenResultDto
 */
export interface TokenResultDto {
    /**
     * 
     * @type {string}
     * @memberof TokenResultDto
     */
    'authenticationToken': string;
    /**
     * 
     * @type {string}
     * @memberof TokenResultDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface UpdateApplicantProfileCommand
 */
export interface UpdateApplicantProfileCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'middleNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'passportNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'alternatePhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'genderId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'ethnicityId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'linkedInProfileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'cvRelativeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApplicantProfileCommand
     */
    'acceptedTermsAndConditions': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'countryOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicantProfileCommand
     */
    'disabilityDetails'?: string | null;
    /**
     * 
     * @type {Array<ApplicantProfileQualificationCreateUpdateModel>}
     * @memberof UpdateApplicantProfileCommand
     */
    'applicantProfileQualifications': Array<ApplicantProfileQualificationCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileWorkExperienceCreateUpdateModel>}
     * @memberof UpdateApplicantProfileCommand
     */
    'applicantProfileWorkExperience': Array<ApplicantProfileWorkExperienceCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileCourseCreateUpdateModel>}
     * @memberof UpdateApplicantProfileCommand
     */
    'applicantProfileCourses': Array<ApplicantProfileCourseCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>}
     * @memberof UpdateApplicantProfileCommand
     */
    'applicantProfileAreasOfDisability': Array<ApplicantProfileAreaOfDisabilityCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserCommand
     */
    'roleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateVacancyApplicationCommand
 */
export interface UpdateVacancyApplicationCommand {
    /**
     * 
     * @type {VacancyApplicationStatus}
     * @memberof UpdateVacancyApplicationCommand
     */
    'vacancyApplicationStatusId': VacancyApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyApplicationCommand
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UpdateVacancyCommand
 */
export interface UpdateVacancyCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'applicationsOpenDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'commencementDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'responsibilities': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateVacancyCommand
     */
    'positionsAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'closedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'openedDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'minimumRequirementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'minimumRequirementYearsOfExperience': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVacancyCommand
     */
    'minimumRequirementSkillsAndKnowledge': string;
    /**
     * 
     * @type {Array<VacancyQuestionCreateUpdateModel>}
     * @memberof UpdateVacancyCommand
     */
    'vacancyQuestions': Array<VacancyQuestionCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface UserViewModel
 */
export interface UserViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserViewModel
     */
    'roleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface VacancyApplicationQuestionAnswerCreateModel
 */
export interface VacancyApplicationQuestionAnswerCreateModel {
    /**
     * 
     * @type {boolean}
     * @memberof VacancyApplicationQuestionAnswerCreateModel
     */
    'answer': boolean;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationQuestionAnswerCreateModel
     */
    'vacancyQuestionId': string;
}
/**
 * 
 * @export
 * @interface VacancyApplicationQuestionAnswerViewModel
 */
export interface VacancyApplicationQuestionAnswerViewModel {
    /**
     * 
     * @type {boolean}
     * @memberof VacancyApplicationQuestionAnswerViewModel
     */
    'answer': boolean;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationQuestionAnswerViewModel
     */
    'vacancyQuestionId': string;
    /**
     * 
     * @type {VacancyApplicationQuestionAnswerViewModelVacancyQuestion}
     * @memberof VacancyApplicationQuestionAnswerViewModel
     */
    'vacancyQuestion': VacancyApplicationQuestionAnswerViewModelVacancyQuestion;
}
/**
 * 
 * @export
 * @interface VacancyApplicationQuestionAnswerViewModelVacancyQuestion
 */
export interface VacancyApplicationQuestionAnswerViewModelVacancyQuestion {
    /**
     * 
     * @type {boolean}
     * @memberof VacancyApplicationQuestionAnswerViewModelVacancyQuestion
     */
    'requiresAffirmativeAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationQuestionAnswerViewModelVacancyQuestion
     */
    'question': string;
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationQuestionAnswerViewModelVacancyQuestion
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationQuestionAnswerViewModelVacancyQuestion
     */
    'id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VacancyApplicationStatus = {
    Open: 'Open',
    Closed: 'Closed',
    Rejected: 'Rejected'
} as const;

export type VacancyApplicationStatus = typeof VacancyApplicationStatus[keyof typeof VacancyApplicationStatus];


/**
 * 
 * @export
 * @interface VacancyApplicationViewModel
 */
export interface VacancyApplicationViewModel {
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModel
     */
    'applicationChannel': string;
    /**
     * 
     * @type {VacancyApplicationStatus}
     * @memberof VacancyApplicationViewModel
     */
    'vacancyApplicationStatusId': VacancyApplicationStatus;
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationViewModel
     */
    'timeToFill': number;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModel
     */
    'submissionDate': string;
    /**
     * 
     * @type {VacancyApplicationViewModelApplicantProfile}
     * @memberof VacancyApplicationViewModel
     */
    'applicantProfile': VacancyApplicationViewModelApplicantProfile;
    /**
     * 
     * @type {VacancyApplicationViewModelVacancy}
     * @memberof VacancyApplicationViewModel
     */
    'vacancy': VacancyApplicationViewModelVacancy;
    /**
     * 
     * @type {Array<VacancyApplicationQuestionAnswerViewModel>}
     * @memberof VacancyApplicationViewModel
     */
    'vacancyApplicationQuestionAnswers': Array<VacancyApplicationQuestionAnswerViewModel>;
}
/**
 * 
 * @export
 * @interface VacancyApplicationViewModelApplicantProfile
 */
export interface VacancyApplicationViewModelApplicantProfile {
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'middleNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'passportNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'alternatePhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'genderId': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'ethnicityId': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'linkedInProfileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'cvRelativeUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'acceptedTermsAndConditions': boolean;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'genderName': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'countryOther'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'disabilityDetails'?: string | null;
    /**
     * 
     * @type {Array<ApplicantProfileQualificationViewModel>}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'applicantProfileQualifications': Array<ApplicantProfileQualificationViewModel>;
    /**
     * 
     * @type {Array<ApplicantProfileWorkExperienceViewModel>}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'applicantProfileWorkExperience': Array<ApplicantProfileWorkExperienceViewModel>;
    /**
     * 
     * @type {Array<ApplicantProfileCourseCreateUpdateModel>}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'applicantProfileCourses': Array<ApplicantProfileCourseCreateUpdateModel>;
    /**
     * 
     * @type {Array<ApplicantProfileAreaOfDisabilityViewModel>}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'applicantProfileAreasOfDisability': Array<ApplicantProfileAreaOfDisabilityViewModel>;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'ethnicityName': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelApplicantProfile
     */
    'countryName': string;
}
/**
 * 
 * @export
 * @interface VacancyApplicationViewModelPagedResult
 */
export interface VacancyApplicationViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<VacancyApplicationViewModel>}
     * @memberof VacancyApplicationViewModelPagedResult
     */
    'data': Array<VacancyApplicationViewModel>;
}
/**
 * 
 * @export
 * @interface VacancyApplicationViewModelVacancy
 */
export interface VacancyApplicationViewModelVacancy {
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'applicationsOpenDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'commencementDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'responsibilities': string;
    /**
     * 
     * @type {number}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'positionsAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'closedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'openedDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'minimumRequirementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'minimumRequirementYearsOfExperience': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'minimumRequirementSkillsAndKnowledge': string;
    /**
     * 
     * @type {Array<VacancyQuestionViewModel>}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'vacancyQuestions': Array<VacancyQuestionViewModel>;
    /**
     * 
     * @type {boolean}
     * @memberof VacancyApplicationViewModelVacancy
     */
    'hasApplications': boolean;
}
/**
 * 
 * @export
 * @interface VacancyQuestionCreateUpdateModel
 */
export interface VacancyQuestionCreateUpdateModel {
    /**
     * 
     * @type {boolean}
     * @memberof VacancyQuestionCreateUpdateModel
     */
    'requiresAffirmativeAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof VacancyQuestionCreateUpdateModel
     */
    'question': string;
    /**
     * 
     * @type {number}
     * @memberof VacancyQuestionCreateUpdateModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof VacancyQuestionCreateUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface VacancyQuestionViewModel
 */
export interface VacancyQuestionViewModel {
    /**
     * 
     * @type {boolean}
     * @memberof VacancyQuestionViewModel
     */
    'requiresAffirmativeAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof VacancyQuestionViewModel
     */
    'question': string;
    /**
     * 
     * @type {number}
     * @memberof VacancyQuestionViewModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof VacancyQuestionViewModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VacancyViewModel
 */
export interface VacancyViewModel {
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'applicationsOpenDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'applicationsCloseDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'commencementDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'responsibilities': string;
    /**
     * 
     * @type {number}
     * @memberof VacancyViewModel
     */
    'positionsAvailable': number;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'closedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'openedDate': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'minimumRequirementQualification': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'minimumRequirementYearsOfExperience': string;
    /**
     * 
     * @type {string}
     * @memberof VacancyViewModel
     */
    'minimumRequirementSkillsAndKnowledge': string;
    /**
     * 
     * @type {Array<VacancyQuestionViewModel>}
     * @memberof VacancyViewModel
     */
    'vacancyQuestions': Array<VacancyQuestionViewModel>;
    /**
     * 
     * @type {boolean}
     * @memberof VacancyViewModel
     */
    'hasApplications': boolean;
}
/**
 * 
 * @export
 * @interface VacancyViewModelPagedResult
 */
export interface VacancyViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof VacancyViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof VacancyViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof VacancyViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof VacancyViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<VacancyViewModel>}
     * @memberof VacancyViewModelPagedResult
     */
    'data': Array<VacancyViewModel>;
}
/**
 * 
 * @export
 * @interface YearsOfExperienceViewModel
 */
export interface YearsOfExperienceViewModel {
    /**
     * 
     * @type {string}
     * @memberof YearsOfExperienceViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof YearsOfExperienceViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YearsOfExperienceViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface YearsOfExperienceViewModelPagedResult
 */
export interface YearsOfExperienceViewModelPagedResult {
    /**
     * 
     * @type {number}
     * @memberof YearsOfExperienceViewModelPagedResult
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof YearsOfExperienceViewModelPagedResult
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof YearsOfExperienceViewModelPagedResult
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof YearsOfExperienceViewModelPagedResult
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<YearsOfExperienceViewModel>}
     * @memberof YearsOfExperienceViewModelPagedResult
     */
    'data': Array<YearsOfExperienceViewModel>;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountConfirmEmailPost: async (apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAccountConfirmEmailPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiAccountForgotPasswordPost', 'emailAddress', emailAddress)
            const localVarPath = `/api/Account/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost: async (apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAccountLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authenticationToken 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenPost: async (authenticationToken: string, refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationToken' is not null or undefined
            assertParamExists('apiAccountRefreshTokenPost', 'authenticationToken', authenticationToken)
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('apiAccountRefreshTokenPost', 'refreshToken', refreshToken)
            const localVarPath = `/api/Account/RefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authenticationToken !== undefined) {
                localVarQueryParameter['authenticationToken'] = authenticationToken;
            }

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost: async (apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAccountRegisterPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} passwordResetToken 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetForgotPasswordPost: async (emailAddress: string, passwordResetToken: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiAccountResetForgotPasswordPost', 'emailAddress', emailAddress)
            // verify required parameter 'passwordResetToken' is not null or undefined
            assertParamExists('apiAccountResetForgotPasswordPost', 'passwordResetToken', passwordResetToken)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('apiAccountResetForgotPasswordPost', 'newPassword', newPassword)
            const localVarPath = `/api/Account/ResetForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (passwordResetToken !== undefined) {
                localVarQueryParameter['passwordResetToken'] = passwordResetToken;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountForgotPasswordPost(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountForgotPasswordPost(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLoginPost(apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLoginPost(apiAccountLoginPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authenticationToken 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRefreshTokenPost(authenticationToken: string, refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRefreshTokenPost(authenticationToken, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRegisterPost(apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRegisterPost(apiAccountRegisterPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} passwordResetToken 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountResetForgotPasswordPost(emailAddress: string, passwordResetToken: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost(emailAddress: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountForgotPasswordPost(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountLoginPost(apiAccountLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authenticationToken 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenPost(authenticationToken: string, refreshToken: string, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountRefreshTokenPost(authenticationToken, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountRegisterPost(apiAccountRegisterPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} passwordResetToken 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetForgotPasswordPost(emailAddress: string, passwordResetToken: string, newPassword: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountForgotPasswordPost(emailAddress: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountForgotPasswordPost(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLoginPost(apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLoginPost(apiAccountLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authenticationToken 
     * @param {string} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRefreshTokenPost(authenticationToken: string, refreshToken: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRefreshTokenPost(authenticationToken, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRegisterPost(apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRegisterPost(apiAccountRegisterPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {string} passwordResetToken 
     * @param {string} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountResetForgotPasswordPost(emailAddress: string, passwordResetToken: string, newPassword: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicantProfilesApi - axios parameter creator
 * @export
 */
export const ApplicantProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesGet: async (pageNumber: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiApplicantProfilesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiApplicantProfilesGet', 'pageSize', pageSize)
            const localVarPath = `/api/applicant-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicantProfilesIdGet', 'id', id)
            const localVarPath = `/api/applicant-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiApplicantProfilesIdGetRequest} [apiApplicantProfilesIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesIdPut: async (id: string, apiApplicantProfilesIdGetRequest?: ApiApplicantProfilesIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicantProfilesIdPut', 'id', id)
            const localVarPath = `/api/applicant-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiApplicantProfilesIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiApplicantProfilesGetRequest} [apiApplicantProfilesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesPost: async (apiApplicantProfilesGetRequest?: ApiApplicantProfilesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applicant-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiApplicantProfilesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicantProfilesApi - functional programming interface
 * @export
 */
export const ApplicantProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicantProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicantProfilesGet(pageNumber: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicantProfileViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicantProfilesGet(pageNumber, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicantProfilesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicantProfileViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicantProfilesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiApplicantProfilesIdGetRequest} [apiApplicantProfilesIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicantProfilesIdPut(id: string, apiApplicantProfilesIdGetRequest?: ApiApplicantProfilesIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicantProfilesIdPut(id, apiApplicantProfilesIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiApplicantProfilesGetRequest} [apiApplicantProfilesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicantProfilesPost(apiApplicantProfilesGetRequest?: ApiApplicantProfilesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringJsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicantProfilesPost(apiApplicantProfilesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicantProfilesApi - factory interface
 * @export
 */
export const ApplicantProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicantProfilesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesGet(pageNumber: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<ApplicantProfileViewModelPagedResult> {
            return localVarFp.apiApplicantProfilesGet(pageNumber, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesIdGet(id: string, options?: any): AxiosPromise<ApplicantProfileViewModel> {
            return localVarFp.apiApplicantProfilesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiApplicantProfilesIdGetRequest} [apiApplicantProfilesIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesIdPut(id: string, apiApplicantProfilesIdGetRequest?: ApiApplicantProfilesIdGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicantProfilesIdPut(id, apiApplicantProfilesIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiApplicantProfilesGetRequest} [apiApplicantProfilesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicantProfilesPost(apiApplicantProfilesGetRequest?: ApiApplicantProfilesGetRequest, options?: any): AxiosPromise<StringJsonResponse> {
            return localVarFp.apiApplicantProfilesPost(apiApplicantProfilesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicantProfilesApi - object-oriented interface
 * @export
 * @class ApplicantProfilesApi
 * @extends {BaseAPI}
 */
export class ApplicantProfilesApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantProfilesApi
     */
    public apiApplicantProfilesGet(pageNumber: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return ApplicantProfilesApiFp(this.configuration).apiApplicantProfilesGet(pageNumber, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantProfilesApi
     */
    public apiApplicantProfilesIdGet(id: string, options?: AxiosRequestConfig) {
        return ApplicantProfilesApiFp(this.configuration).apiApplicantProfilesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiApplicantProfilesIdGetRequest} [apiApplicantProfilesIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantProfilesApi
     */
    public apiApplicantProfilesIdPut(id: string, apiApplicantProfilesIdGetRequest?: ApiApplicantProfilesIdGetRequest, options?: AxiosRequestConfig) {
        return ApplicantProfilesApiFp(this.configuration).apiApplicantProfilesIdPut(id, apiApplicantProfilesIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApplicantProfilesGetRequest} [apiApplicantProfilesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantProfilesApi
     */
    public apiApplicantProfilesPost(apiApplicantProfilesGetRequest?: ApiApplicantProfilesGetRequest, options?: AxiosRequestConfig) {
        return ApplicantProfilesApiFp(this.configuration).apiApplicantProfilesPost(apiApplicantProfilesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AreasOfDisabilityApi - axios parameter creator
 * @export
 */
export const AreasOfDisabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAreasOfDisabilityAreaOfDisabilitiesGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiAreasOfDisabilityAreaOfDisabilitiesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiAreasOfDisabilityAreaOfDisabilitiesGet', 'pageSize', pageSize)
            const localVarPath = `/api/areas-of-disability/area-of-disabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreasOfDisabilityApi - functional programming interface
 * @export
 */
export const AreasOfDisabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreasOfDisabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAreasOfDisabilityAreaOfDisabilitiesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaOfDisabilityViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAreasOfDisabilityAreaOfDisabilitiesGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreasOfDisabilityApi - factory interface
 * @export
 */
export const AreasOfDisabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreasOfDisabilityApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAreasOfDisabilityAreaOfDisabilitiesGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<AreaOfDisabilityViewModelPagedResult> {
            return localVarFp.apiAreasOfDisabilityAreaOfDisabilitiesGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreasOfDisabilityApi - object-oriented interface
 * @export
 * @class AreasOfDisabilityApi
 * @extends {BaseAPI}
 */
export class AreasOfDisabilityApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreasOfDisabilityApi
     */
    public apiAreasOfDisabilityAreaOfDisabilitiesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return AreasOfDisabilityApiFp(this.configuration).apiAreasOfDisabilityAreaOfDisabilitiesGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountriesGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiCountriesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiCountriesGet', 'pageSize', pageSize)
            const localVarPath = `/api/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCountriesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCountriesGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCountriesGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<CountryViewModelPagedResult> {
            return localVarFp.apiCountriesGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public apiCountriesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).apiCountriesGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EthnicitiesApi - axios parameter creator
 * @export
 */
export const EthnicitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEthnicitiesGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiEthnicitiesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiEthnicitiesGet', 'pageSize', pageSize)
            const localVarPath = `/api/ethnicities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EthnicitiesApi - functional programming interface
 * @export
 */
export const EthnicitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EthnicitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEthnicitiesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EthnicityViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEthnicitiesGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EthnicitiesApi - factory interface
 * @export
 */
export const EthnicitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EthnicitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEthnicitiesGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<EthnicityViewModelPagedResult> {
            return localVarFp.apiEthnicitiesGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EthnicitiesApi - object-oriented interface
 * @export
 * @class EthnicitiesApi
 * @extends {BaseAPI}
 */
export class EthnicitiesApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EthnicitiesApi
     */
    public apiEthnicitiesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return EthnicitiesApiFp(this.configuration).apiEthnicitiesGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldsOfStudyApi - axios parameter creator
 * @export
 */
export const FieldsOfStudyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldsOfStudyFieldOfStudiesGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiFieldsOfStudyFieldOfStudiesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiFieldsOfStudyFieldOfStudiesGet', 'pageSize', pageSize)
            const localVarPath = `/api/fields-of-study/field-of-studies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldsOfStudyApi - functional programming interface
 * @export
 */
export const FieldsOfStudyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldsOfStudyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldsOfStudyFieldOfStudiesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldOfStudyViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldsOfStudyFieldOfStudiesGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldsOfStudyApi - factory interface
 * @export
 */
export const FieldsOfStudyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldsOfStudyApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldsOfStudyFieldOfStudiesGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<FieldOfStudyViewModelPagedResult> {
            return localVarFp.apiFieldsOfStudyFieldOfStudiesGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldsOfStudyApi - object-oriented interface
 * @export
 * @class FieldsOfStudyApi
 * @extends {BaseAPI}
 */
export class FieldsOfStudyApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsOfStudyApi
     */
    public apiFieldsOfStudyFieldOfStudiesGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return FieldsOfStudyApiFp(this.configuration).apiFieldsOfStudyFieldOfStudiesGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesViewRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/view/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet: async (relativePath: string, width: number, height: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'relativePath', relativePath)
            // verify required parameter 'width' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'width', width)
            // verify required parameter 'height' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'height', height)
            const localVarPath = `/files/{width}/{height}/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)))
                .replace(`{${"width"}}`, encodeURIComponent(String(width)))
                .replace(`{${"height"}}`, encodeURIComponent(String(height)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesViewRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesWidthHeightRelativePathGet(relativePath, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost(file?: File, options?: any): AxiosPromise<FilePath> {
            return localVarFp.filesPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesViewRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: any): AxiosPromise<string> {
            return localVarFp.filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPost(file?: File, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesViewRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GendersApi - axios parameter creator
 * @export
 */
export const GendersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGendersGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiGendersGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiGendersGet', 'pageSize', pageSize)
            const localVarPath = `/api/genders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GendersApi - functional programming interface
 * @export
 */
export const GendersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GendersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGendersGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenderViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGendersGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GendersApi - factory interface
 * @export
 */
export const GendersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GendersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGendersGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<GenderViewModelPagedResult> {
            return localVarFp.apiGendersGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GendersApi - object-oriented interface
 * @export
 * @class GendersApi
 * @extends {BaseAPI}
 */
export class GendersApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public apiGendersGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return GendersApiFp(this.configuration).apiGendersGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QualificationsApi - axios parameter creator
 * @export
 */
export const QualificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQualificationsGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiQualificationsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiQualificationsGet', 'pageSize', pageSize)
            const localVarPath = `/api/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QualificationsApi - functional programming interface
 * @export
 */
export const QualificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QualificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQualificationsGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualificationViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQualificationsGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QualificationsApi - factory interface
 * @export
 */
export const QualificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QualificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQualificationsGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<QualificationViewModelPagedResult> {
            return localVarFp.apiQualificationsGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QualificationsApi - object-oriented interface
 * @export
 * @class QualificationsApi
 * @extends {BaseAPI}
 */
export class QualificationsApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationsApi
     */
    public apiQualificationsGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return QualificationsApiFp(this.configuration).apiQualificationsGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet(options?: any): AxiosPromise<Array<RoleListViewModel>> {
            return localVarFp.apiRolesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesGet(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdDelete', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdGet', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdPut: async (id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdPut', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiUsersIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost: async (apiUsersGetRequest?: ApiUsersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiUsersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdPut(id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdPut(id, apiUsersIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPost(apiUsersGetRequest?: ApiUsersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringJsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPost(apiUsersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(options?: any): AxiosPromise<Array<UserViewModel>> {
            return localVarFp.apiUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet(id: string, options?: any): AxiosPromise<UserViewModel> {
            return localVarFp.apiUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdPut(id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersIdPut(id, apiUsersIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(apiUsersGetRequest?: ApiUsersGetRequest, options?: any): AxiosPromise<StringJsonResponse> {
            return localVarFp.apiUsersPost(apiUsersGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdPut(id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdPut(id, apiUsersIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPost(apiUsersGetRequest?: ApiUsersGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersPost(apiUsersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VacanciesApi - axios parameter creator
 * @export
 */
export const VacanciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} isActive 
         * @param {string} [country] 
         * @param {string} [location] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesGet: async (pageNumber: number, pageSize: number, isActive: boolean, country?: string, location?: string, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiVacanciesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiVacanciesGet', 'pageSize', pageSize)
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('apiVacanciesGet', 'isActive', isActive)
            const localVarPath = `/api/vacancies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacanciesIdDelete', 'id', id)
            const localVarPath = `/api/vacancies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacanciesIdGet', 'id', id)
            const localVarPath = `/api/vacancies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiVacanciesIdDeleteRequest} [apiVacanciesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesIdPut: async (id: string, apiVacanciesIdDeleteRequest?: ApiVacanciesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacanciesIdPut', 'id', id)
            const localVarPath = `/api/vacancies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiVacanciesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiVacanciesGetRequest} [apiVacanciesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesPost: async (apiVacanciesGetRequest?: ApiVacanciesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vacancies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiVacanciesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacanciesApi - functional programming interface
 * @export
 */
export const VacanciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacanciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} isActive 
         * @param {string} [country] 
         * @param {string} [location] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacanciesGet(pageNumber: number, pageSize: number, isActive: boolean, country?: string, location?: string, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacanciesGet(pageNumber, pageSize, isActive, country, location, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacanciesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacanciesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacanciesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacanciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiVacanciesIdDeleteRequest} [apiVacanciesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacanciesIdPut(id: string, apiVacanciesIdDeleteRequest?: ApiVacanciesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacanciesIdPut(id, apiVacanciesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiVacanciesGetRequest} [apiVacanciesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacanciesPost(apiVacanciesGetRequest?: ApiVacanciesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidJsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacanciesPost(apiVacanciesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VacanciesApi - factory interface
 * @export
 */
export const VacanciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacanciesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} isActive 
         * @param {string} [country] 
         * @param {string} [location] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesGet(pageNumber: number, pageSize: number, isActive: boolean, country?: string, location?: string, searchTerm?: string, options?: any): AxiosPromise<VacancyViewModelPagedResult> {
            return localVarFp.apiVacanciesGet(pageNumber, pageSize, isActive, country, location, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVacanciesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesIdGet(id: string, options?: any): AxiosPromise<VacancyViewModel> {
            return localVarFp.apiVacanciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiVacanciesIdDeleteRequest} [apiVacanciesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesIdPut(id: string, apiVacanciesIdDeleteRequest?: ApiVacanciesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiVacanciesIdPut(id, apiVacanciesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiVacanciesGetRequest} [apiVacanciesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacanciesPost(apiVacanciesGetRequest?: ApiVacanciesGetRequest, options?: any): AxiosPromise<GuidJsonResponse> {
            return localVarFp.apiVacanciesPost(apiVacanciesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacanciesApi - object-oriented interface
 * @export
 * @class VacanciesApi
 * @extends {BaseAPI}
 */
export class VacanciesApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {boolean} isActive 
     * @param {string} [country] 
     * @param {string} [location] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacanciesApi
     */
    public apiVacanciesGet(pageNumber: number, pageSize: number, isActive: boolean, country?: string, location?: string, searchTerm?: string, options?: AxiosRequestConfig) {
        return VacanciesApiFp(this.configuration).apiVacanciesGet(pageNumber, pageSize, isActive, country, location, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacanciesApi
     */
    public apiVacanciesIdDelete(id: string, options?: AxiosRequestConfig) {
        return VacanciesApiFp(this.configuration).apiVacanciesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacanciesApi
     */
    public apiVacanciesIdGet(id: string, options?: AxiosRequestConfig) {
        return VacanciesApiFp(this.configuration).apiVacanciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiVacanciesIdDeleteRequest} [apiVacanciesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacanciesApi
     */
    public apiVacanciesIdPut(id: string, apiVacanciesIdDeleteRequest?: ApiVacanciesIdDeleteRequest, options?: AxiosRequestConfig) {
        return VacanciesApiFp(this.configuration).apiVacanciesIdPut(id, apiVacanciesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiVacanciesGetRequest} [apiVacanciesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacanciesApi
     */
    public apiVacanciesPost(apiVacanciesGetRequest?: ApiVacanciesGetRequest, options?: AxiosRequestConfig) {
        return VacanciesApiFp(this.configuration).apiVacanciesPost(apiVacanciesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VacancyApplicationsApi - axios parameter creator
 * @export
 */
export const VacancyApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiVacancyApplicationsBulkUpdateStatusPutRequest} [apiVacancyApplicationsBulkUpdateStatusPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsBulkUpdateStatusPut: async (apiVacancyApplicationsBulkUpdateStatusPutRequest?: ApiVacancyApplicationsBulkUpdateStatusPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vacancy-applications/bulk-update-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiVacancyApplicationsBulkUpdateStatusPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [vacancyId] 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [ethnicityId] 
         * @param {string} [location] 
         * @param {string} [genderId] 
         * @param {string} [qualificationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsGet: async (pageNumber: number, pageSize: number, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, ethnicityId?: string, location?: string, genderId?: string, qualificationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiVacancyApplicationsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiVacancyApplicationsGet', 'pageSize', pageSize)
            const localVarPath = `/api/vacancy-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (vacancyId !== undefined) {
                localVarQueryParameter['vacancyId'] = vacancyId;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (ethnicityId !== undefined) {
                localVarQueryParameter['ethnicityId'] = ethnicityId;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (genderId !== undefined) {
                localVarQueryParameter['genderId'] = genderId;
            }

            if (qualificationId !== undefined) {
                localVarQueryParameter['qualificationId'] = qualificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyApplicationsIdDelete', 'id', id)
            const localVarPath = `/api/vacancy-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyApplicationsIdGet', 'id', id)
            const localVarPath = `/api/vacancy-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiVacancyApplicationsIdDeleteRequest} [apiVacancyApplicationsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsIdPut: async (id: string, apiVacancyApplicationsIdDeleteRequest?: ApiVacancyApplicationsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyApplicationsIdPut', 'id', id)
            const localVarPath = `/api/vacancy-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiVacancyApplicationsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiVacancyApplicationsGetRequest} [apiVacancyApplicationsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsPost: async (apiVacancyApplicationsGetRequest?: ApiVacancyApplicationsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vacancy-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiVacancyApplicationsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} includeShortlistColumns 
         * @param {string} [vacancyId] 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [location] 
         * @param {string} [genderId] 
         * @param {string} [qualificationId] 
         * @param {string} [ethnicityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsReportGet: async (pageNumber: number, pageSize: number, includeShortlistColumns: boolean, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, location?: string, genderId?: string, qualificationId?: string, ethnicityId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiVacancyApplicationsReportGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiVacancyApplicationsReportGet', 'pageSize', pageSize)
            // verify required parameter 'includeShortlistColumns' is not null or undefined
            assertParamExists('apiVacancyApplicationsReportGet', 'includeShortlistColumns', includeShortlistColumns)
            const localVarPath = `/api/vacancy-applications/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (vacancyId !== undefined) {
                localVarQueryParameter['vacancyId'] = vacancyId;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (includeShortlistColumns !== undefined) {
                localVarQueryParameter['includeShortlistColumns'] = includeShortlistColumns;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (genderId !== undefined) {
                localVarQueryParameter['genderId'] = genderId;
            }

            if (qualificationId !== undefined) {
                localVarQueryParameter['qualificationId'] = qualificationId;
            }

            if (ethnicityId !== undefined) {
                localVarQueryParameter['ethnicityId'] = ethnicityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyApplicationsApi - functional programming interface
 * @export
 */
export const VacancyApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiVacancyApplicationsBulkUpdateStatusPutRequest} [apiVacancyApplicationsBulkUpdateStatusPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsBulkUpdateStatusPut(apiVacancyApplicationsBulkUpdateStatusPutRequest?: ApiVacancyApplicationsBulkUpdateStatusPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsBulkUpdateStatusPut(apiVacancyApplicationsBulkUpdateStatusPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [vacancyId] 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [ethnicityId] 
         * @param {string} [location] 
         * @param {string} [genderId] 
         * @param {string} [qualificationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsGet(pageNumber: number, pageSize: number, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, ethnicityId?: string, location?: string, genderId?: string, qualificationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyApplicationViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsGet(pageNumber, pageSize, vacancyId, searchTerm, startDate, endDate, ethnicityId, location, genderId, qualificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyApplicationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiVacancyApplicationsIdDeleteRequest} [apiVacancyApplicationsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsIdPut(id: string, apiVacancyApplicationsIdDeleteRequest?: ApiVacancyApplicationsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsIdPut(id, apiVacancyApplicationsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiVacancyApplicationsGetRequest} [apiVacancyApplicationsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsPost(apiVacancyApplicationsGetRequest?: ApiVacancyApplicationsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidJsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsPost(apiVacancyApplicationsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} includeShortlistColumns 
         * @param {string} [vacancyId] 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [location] 
         * @param {string} [genderId] 
         * @param {string} [qualificationId] 
         * @param {string} [ethnicityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyApplicationsReportGet(pageNumber: number, pageSize: number, includeShortlistColumns: boolean, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, location?: string, genderId?: string, qualificationId?: string, ethnicityId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyApplicationsReportGet(pageNumber, pageSize, includeShortlistColumns, vacancyId, searchTerm, startDate, endDate, location, genderId, qualificationId, ethnicityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VacancyApplicationsApi - factory interface
 * @export
 */
export const VacancyApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiVacancyApplicationsBulkUpdateStatusPutRequest} [apiVacancyApplicationsBulkUpdateStatusPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsBulkUpdateStatusPut(apiVacancyApplicationsBulkUpdateStatusPutRequest?: ApiVacancyApplicationsBulkUpdateStatusPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiVacancyApplicationsBulkUpdateStatusPut(apiVacancyApplicationsBulkUpdateStatusPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [vacancyId] 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [ethnicityId] 
         * @param {string} [location] 
         * @param {string} [genderId] 
         * @param {string} [qualificationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsGet(pageNumber: number, pageSize: number, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, ethnicityId?: string, location?: string, genderId?: string, qualificationId?: string, options?: any): AxiosPromise<VacancyApplicationViewModelPagedResult> {
            return localVarFp.apiVacancyApplicationsGet(pageNumber, pageSize, vacancyId, searchTerm, startDate, endDate, ethnicityId, location, genderId, qualificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVacancyApplicationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsIdGet(id: string, options?: any): AxiosPromise<VacancyApplicationViewModel> {
            return localVarFp.apiVacancyApplicationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiVacancyApplicationsIdDeleteRequest} [apiVacancyApplicationsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsIdPut(id: string, apiVacancyApplicationsIdDeleteRequest?: ApiVacancyApplicationsIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiVacancyApplicationsIdPut(id, apiVacancyApplicationsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiVacancyApplicationsGetRequest} [apiVacancyApplicationsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsPost(apiVacancyApplicationsGetRequest?: ApiVacancyApplicationsGetRequest, options?: any): AxiosPromise<GuidJsonResponse> {
            return localVarFp.apiVacancyApplicationsPost(apiVacancyApplicationsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} includeShortlistColumns 
         * @param {string} [vacancyId] 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [location] 
         * @param {string} [genderId] 
         * @param {string} [qualificationId] 
         * @param {string} [ethnicityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyApplicationsReportGet(pageNumber: number, pageSize: number, includeShortlistColumns: boolean, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, location?: string, genderId?: string, qualificationId?: string, ethnicityId?: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.apiVacancyApplicationsReportGet(pageNumber, pageSize, includeShortlistColumns, vacancyId, searchTerm, startDate, endDate, location, genderId, qualificationId, ethnicityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyApplicationsApi - object-oriented interface
 * @export
 * @class VacancyApplicationsApi
 * @extends {BaseAPI}
 */
export class VacancyApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {ApiVacancyApplicationsBulkUpdateStatusPutRequest} [apiVacancyApplicationsBulkUpdateStatusPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsBulkUpdateStatusPut(apiVacancyApplicationsBulkUpdateStatusPutRequest?: ApiVacancyApplicationsBulkUpdateStatusPutRequest, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsBulkUpdateStatusPut(apiVacancyApplicationsBulkUpdateStatusPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [vacancyId] 
     * @param {string} [searchTerm] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [ethnicityId] 
     * @param {string} [location] 
     * @param {string} [genderId] 
     * @param {string} [qualificationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsGet(pageNumber: number, pageSize: number, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, ethnicityId?: string, location?: string, genderId?: string, qualificationId?: string, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsGet(pageNumber, pageSize, vacancyId, searchTerm, startDate, endDate, ethnicityId, location, genderId, qualificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsIdDelete(id: string, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsIdGet(id: string, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiVacancyApplicationsIdDeleteRequest} [apiVacancyApplicationsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsIdPut(id: string, apiVacancyApplicationsIdDeleteRequest?: ApiVacancyApplicationsIdDeleteRequest, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsIdPut(id, apiVacancyApplicationsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiVacancyApplicationsGetRequest} [apiVacancyApplicationsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsPost(apiVacancyApplicationsGetRequest?: ApiVacancyApplicationsGetRequest, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsPost(apiVacancyApplicationsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {boolean} includeShortlistColumns 
     * @param {string} [vacancyId] 
     * @param {string} [searchTerm] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [location] 
     * @param {string} [genderId] 
     * @param {string} [qualificationId] 
     * @param {string} [ethnicityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApplicationsApi
     */
    public apiVacancyApplicationsReportGet(pageNumber: number, pageSize: number, includeShortlistColumns: boolean, vacancyId?: string, searchTerm?: string, startDate?: string, endDate?: string, location?: string, genderId?: string, qualificationId?: string, ethnicityId?: string, options?: AxiosRequestConfig) {
        return VacancyApplicationsApiFp(this.configuration).apiVacancyApplicationsReportGet(pageNumber, pageSize, includeShortlistColumns, vacancyId, searchTerm, startDate, endDate, location, genderId, qualificationId, ethnicityId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VacancyCountriesApi - axios parameter creator
 * @export
 */
export const VacancyCountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} includeInactiveVacancies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyCountriesGet: async (includeInactiveVacancies: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeInactiveVacancies' is not null or undefined
            assertParamExists('apiVacancyCountriesGet', 'includeInactiveVacancies', includeInactiveVacancies)
            const localVarPath = `/api/vacancy-countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeInactiveVacancies !== undefined) {
                localVarQueryParameter['includeInactiveVacancies'] = includeInactiveVacancies;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyCountriesApi - functional programming interface
 * @export
 */
export const VacancyCountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyCountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} includeInactiveVacancies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyCountriesGet(includeInactiveVacancies: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyCountriesGet(includeInactiveVacancies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VacancyCountriesApi - factory interface
 * @export
 */
export const VacancyCountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyCountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} includeInactiveVacancies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyCountriesGet(includeInactiveVacancies: boolean, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiVacancyCountriesGet(includeInactiveVacancies, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyCountriesApi - object-oriented interface
 * @export
 * @class VacancyCountriesApi
 * @extends {BaseAPI}
 */
export class VacancyCountriesApi extends BaseAPI {
    /**
     * 
     * @param {boolean} includeInactiveVacancies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCountriesApi
     */
    public apiVacancyCountriesGet(includeInactiveVacancies: boolean, options?: AxiosRequestConfig) {
        return VacancyCountriesApiFp(this.configuration).apiVacancyCountriesGet(includeInactiveVacancies, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VacancyLocationsApi - axios parameter creator
 * @export
 */
export const VacancyLocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyLocationsCountryGet: async (country: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('apiVacancyLocationsCountryGet', 'country', country)
            const localVarPath = `/api/vacancy-locations/{country}`
                .replace(`{${"country"}}`, encodeURIComponent(String(country)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyLocationsApi - functional programming interface
 * @export
 */
export const VacancyLocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyLocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyLocationsCountryGet(country: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyLocationsCountryGet(country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VacancyLocationsApi - factory interface
 * @export
 */
export const VacancyLocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyLocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyLocationsCountryGet(country: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiVacancyLocationsCountryGet(country, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyLocationsApi - object-oriented interface
 * @export
 * @class VacancyLocationsApi
 * @extends {BaseAPI}
 */
export class VacancyLocationsApi extends BaseAPI {
    /**
     * 
     * @param {string} country 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyLocationsApi
     */
    public apiVacancyLocationsCountryGet(country: string, options?: AxiosRequestConfig) {
        return VacancyLocationsApiFp(this.configuration).apiVacancyLocationsCountryGet(country, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YearsOfExperienceApi - axios parameter creator
 * @export
 */
export const YearsOfExperienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYearsOfExperienceGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiYearsOfExperienceGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiYearsOfExperienceGet', 'pageSize', pageSize)
            const localVarPath = `/api/years-of-experience`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YearsOfExperienceApi - functional programming interface
 * @export
 */
export const YearsOfExperienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YearsOfExperienceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYearsOfExperienceGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YearsOfExperienceViewModelPagedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYearsOfExperienceGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YearsOfExperienceApi - factory interface
 * @export
 */
export const YearsOfExperienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YearsOfExperienceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYearsOfExperienceGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<YearsOfExperienceViewModelPagedResult> {
            return localVarFp.apiYearsOfExperienceGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * YearsOfExperienceApi - object-oriented interface
 * @export
 * @class YearsOfExperienceApi
 * @extends {BaseAPI}
 */
export class YearsOfExperienceApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YearsOfExperienceApi
     */
    public apiYearsOfExperienceGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return YearsOfExperienceApiFp(this.configuration).apiYearsOfExperienceGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


