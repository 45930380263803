import { handleError } from "@/utilities/api";
import { VacancyApplicationViewModelPagedResult, VacancyApplicationViewModel, CreateVacancyApplicationCommand, VacancyApplicationStatus } from "@/api-client";
import { ElMessage } from "element-plus";
import { VacancyApplications } from "@/network/api";

export function vacancyApplicationsGet(
  pgNum: number = 1,
  pgSize: number = 10,
  vacancyId: string | undefined = undefined,
  searchTerm: string | undefined = undefined,
  startDate: string | undefined = undefined,
  endDate: string | undefined = undefined,
  location: string | undefined = undefined,
  genderId: string | undefined = undefined,
  qualificationId: string | undefined = undefined,
  ethnicityId: string | undefined = undefined
): Promise<VacancyApplicationViewModelPagedResult> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsGet(pgNum, pgSize, vacancyId, searchTerm, startDate, endDate, ethnicityId, location, genderId, qualificationId)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyApplicationsGetId(id: string): Promise<VacancyApplicationViewModel> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsIdGet(id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyApplicationsPost(item: CreateVacancyApplicationCommand): Promise<string> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsPost(item)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.success({ message: "Application sent!", showClose: true, grouping: true });
          resolve(res.data.value);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyApplicationsPut(item: VacancyApplicationViewModel): Promise<void> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsIdPut(item.id as string, item)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.success({ message: "Updated!", showClose: true, grouping: true });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyApplicationsDelete(id: string): Promise<void> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsIdDelete(id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.error({ message: "Item deleted.", showClose: true, grouping: true });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyApplicationsReport(
  pgSize: number = 10,
  pgNum: number = 1,
  includeShortlistColumns: boolean = false,
  vacancyId: string | undefined = undefined,
  searchTerm: string | undefined = undefined,
  startDate: string | undefined = undefined,
  endDate: string | undefined = undefined,
  location: string | undefined = undefined,
  genderId: string | undefined = undefined,
  qualificationId: string | undefined = undefined,
  ethnicityId: string | undefined = undefined
): Promise<any | undefined> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsReportGet(pgSize, pgNum, includeShortlistColumns, vacancyId, searchTerm, startDate, endDate, location, genderId, qualificationId, ethnicityId, {
      responseType: "arraybuffer",
    })
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function vacancyApplicationsBulkReject(
  vacancyId: string | undefined = undefined,
  searchTerm: string | undefined = undefined,
  startDate: string | undefined = undefined,
  endDate: string | undefined = undefined,
  location: string | undefined = undefined,
  genderId: string | undefined = undefined,
  qualificationId: string | undefined = undefined,
  ethnicityId: string | undefined = undefined,
): Promise<void> {
  return new Promise((resolve, reject) => {
    VacancyApplications.apiVacancyApplicationsBulkUpdateStatusPut({
      vacancyId: vacancyId,
      searchTerm: searchTerm,
      startDate: startDate,
      endDate: endDate,
      ethnicityId: ethnicityId,
      location: location,
      genderId: genderId,
      qualificationId: qualificationId,
      vacancyApplicationStatusId: VacancyApplicationStatus.Rejected,
    })
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.success({ message: "Filtered applications rejected successfully.", showClose: true, grouping: true });
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}
