import { EpPropMergeType } from "element-plus/es/utils";
import { RouteLocationRaw } from "vue-router";
import router from '@/router';

export const uploadPath = `${process.env.VUE_APP_ROOT_API}/files`

export function scrollTop(toScrollSelector = "", scrollIfAbove = true) {
  if (toScrollSelector) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    if (destination) {
      // const navBar = document.querySelector("#navbarNav") as HTMLElement;
      // - (navBar ? navBar.offsetHeight : 0)
      const scrollOffset = destination.offsetTop;

      if (scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }

      // setTimeout used to send to end of stack
      setTimeout(() => {
        window.scrollTo(0, scrollOffset);
      }, 0);
    }
  } else {
    window.scrollTo(0, 0);
  }

  return;
}

export function hasDateTimePassed(targetDateTime: string | undefined) {
  if (targetDateTime) {
    // Parse the target date and time string into a Date object
    const targetDate = new Date(targetDateTime);

    // Get the current date and time
    const currentDate = new Date();

    // Compare the target date and time with the current date and time
    return targetDate < currentDate;
  }
  return false;
}

export function isTodayInRange(startDate: string, endDate: string) {
  // Parse the input dates
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Get the current date
  const currentDate = new Date();

  // Adjust the end date to include the full day
  endDateObj.setHours(23, 59, 59, 999);

  // Check if the current date is greater than or equal to the start date
  // and less than or equal to the end date
  return currentDate >= startDateObj && currentDate <= endDateObj;
}

export function getDateDifference(date: string | undefined) {
  if (date) {
    // Parse the input date
    const inputDate = new Date(date) as any;

    // Get the current date
    const currentDate = new Date() as any;

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDate;

    // Calculate the number of milliseconds in a day, month, and year
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const millisecondsPerMonth = 30.44 * millisecondsPerDay; // Approximate value
    const millisecondsPerYear = 365.25 * millisecondsPerDay; // Approximate value

    // Calculate the difference in days, months, and years
    const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
    const monthsDifference = Math.floor(timeDifference / millisecondsPerMonth);
    const yearsDifference = Math.floor(timeDifference / millisecondsPerYear);

    // Determine whether to use "day(s)," "month(s)," or "year(s)"
    let unit = "day";
    let hasPassed = false;
    let difference = daysDifference;

    if(hasDateTimePassed(date)) {
      hasPassed = true;
    }

    if ((hasPassed && yearsDifference >= 1) || (!hasPassed && Math.abs(yearsDifference + 1) >= 1)) {
      unit = "year";
      difference = !hasPassed ? Math.abs(yearsDifference + 1) : yearsDifference;
    } else if ((hasPassed && monthsDifference >= 1) || (!hasPassed && Math.abs(monthsDifference + 1) >= 1)) {
      unit = "month";
      difference = !hasPassed ? Math.abs(monthsDifference + 1) : monthsDifference;
    }

    if(!daysDifference && !monthsDifference && !yearsDifference) {
      return "today"
    } else if(hasPassed) {
      return `${Math.abs(difference)} ${unit}${Math.abs(difference) !== 1 ? "s" : ""} ago`;
    } else {
      return `${Math.abs(difference)} ${unit}${Math.abs(difference) !== 1 ? "s" : ""}`;
    }
    
  }
  return "";
}

export function addDays(date: Date, days: number) {
  const dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() + days);
  return dateCopy;
}

export function formatDate(date: string, separator = "/") {
  if (date) {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${appendLeadingZeroes(day)}${separator}${appendLeadingZeroes(month)}${separator}${year}`;
  }
  return "";
}

export function formatTime(date: string) {
  if (date) {
    const dateObject = new Date(date);
    const hours: string | number = appendLeadingZeroes(dateObject.getHours());
    const minutes: string | number = appendLeadingZeroes(dateObject.getMinutes());
    return `${hours}:${minutes}`;
  }
  return "";
}

function appendLeadingZeroes(n: any) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function checkStore(state: any, stateItem: string, storeName: string, getterName: string) {
  if (state && state[stateItem] != null) {
    return state[stateItem];
  } else {
    const pinia = localStorage.getItem("pinia");
    const store = JSON.parse(pinia!);
    if (store == null) {
      return null;
    }
    if (store[storeName] == null) {
      return null;
    }
    const storageItem = store[storeName][getterName];
    if (storageItem != null) {
      return storageItem;
    }

    return null;
  }
}

export function applicationStatusFilter(status: any) {
  const statusMap: { [key: string]: string } = {
    Open: "success",
    Closed: "info",
    Rejected: "danger",
  };
  return statusMap[status] as EpPropMergeType<StringConstructor, "" | "success" | "warning" | "info" | "danger", unknown> | undefined;
}

export function vacancyStatusFilter(status: any) {
  const statusMap: { [key: string]: string } = {
    false: "success",
    true: "danger",
  };
  return statusMap[status] as EpPropMergeType<StringConstructor, "" | "success" | "warning" | "info" | "danger", unknown> | undefined;
}


export const goBack = (route: RouteLocationRaw): void => {
  // For the sake of maintaining the query string, this method determines if there is a route to go back to
  // It needs to be used in conjunction with code in the router/index.ts router.beforeEach section
  // Search this reference for all related code & examples: queryCoolness
  if(router.currentRoute.value.meta.fromRoute) {
    router.back()
  } else {
    router.push(route)
  }
}

// This is an identifier for arrays. It is needed to determine if a query should be converted into an array or remain a string
// Search this reference for all related code & examples: queryCoolness
const arrIdentifier = '%-%';

export function handleQueries(queries:any):Promise<any> {
  // This function is used in onBeforeMount to check the existing queries and send them back to the relevant variable
  // Search this reference for all related code & examples: queryCoolness
  const returnQueries: any = { ... queries };
  return new Promise((resolve, reject) => {
    for (const key in returnQueries) {
      const currentQuery = router.currentRoute.value.query[key];
      if(currentQuery != undefined) {
        returnQueries[key] = currentQuery.includes(arrIdentifier) ? (currentQuery as string).split(arrIdentifier) : currentQuery;
      }
    }
    resolve(returnQueries);
  })
}

export function updateQueries(queries:any, ignoreDefaultPage = true):Promise<void> {
  // This function is used after a GET method when filters have changed. It updates the queries in the url accordingly
  // Search this reference for all related code & examples: queryCoolness
  const updatedQueries: any = { ... queries };
  const searchParams = new URLSearchParams(window.location.search);

  return new Promise((resolve, reject) => {
    for (const key in updatedQueries) {
      if(searchParams.has(key)) {
        if(!updatedQueries[key] || ['undefined', undefined].includes(updatedQueries[key])) {
          searchParams.delete(key)
        } else {
          searchParams.set(key, Array.isArray(updatedQueries[key]) ? updatedQueries[key].join(arrIdentifier) : updatedQueries[key])
        }
      } else if(updatedQueries[key] && !['undefined', undefined].includes(updatedQueries[key])) {
        searchParams.append(key, Array.isArray(updatedQueries[key]) ? updatedQueries[key].join(arrIdentifier) : updatedQueries[key])
      }

      // Remove pageNumber query if default page
      if(ignoreDefaultPage && key == 'pageNumber' && +updatedQueries[key] == 1) {
        searchParams.delete(key);
      }
    }
    router.replace(`${router.currentRoute.value.path}?${searchParams.toString()}`);
    resolve();
  })
}

